import axios from 'axios'

import { store } from '../_store/store'
import announcementService from './announcementService'
import { initService } from './initService'

const authService = {
    async initialize() {

    this.checkAxiosAuthHeaderAndRefreshIfNull()
    await this.fetchUserInfoFromApi()
  },

  async fetchUserInfoFromApi() {
    const accessToken = localStorage.getItem('access')
    if (!accessToken) return null

    try {
      const userInfoResponse = await axios.get(
        `${window._env_.REACT_APP_API_URL}/login/user_information/`,
        {
          headers: { Authorization: 'JWT ' + accessToken },
        }
      )

      const userInfo = userInfoResponse.data
      store.dispatch({ type: 'SET_USER_INFO', payload: userInfo })
      return userInfo
    } catch (error) {
      console.error('Failed to fetch user info:', error)
      return null
    }
  },

  getUserInfo() {
    let userInfo = store.getState().authentication.userInfo
    // if (!userInfo) {
    //   userInfo = await this.fetchUserInfoFromApi()
    // }
    return userInfo
  },

  isAuthenticated(prop) {
    let { allowed } = { ...prop }
    allowed = Array.isArray(allowed) ? allowed : []
    let userInfo = store.getState().authentication.userInfo // Retrieve userInfo from the store

    // If userInfo is not in the state, check if a token is available in local storage
    const accessToken = localStorage.getItem('access')

    if (!userInfo && accessToken) {
      // Fetch user info using the access token
      return this.fetchUserInfoFromApi()
        .then(userInfo => {
          if (!userInfo) return false

          // Now perform the group check logic as usual
          const { groups } = userInfo
          if (!Array.isArray(groups) || groups.length === 0) return false

          const groupNames = groups.map(group => group.name)
          const intersection = allowed.filter(group =>
            groupNames.includes(group)
          )

          return intersection.length > 0
        })
        .catch(error => {
          console.error('Error fetching user info:', error)
          return false
        })
    }

    // If userInfo is already in Redux state, continue with the group checks
    if (userInfo) {
      var { groups } = userInfo

      if (!Array.isArray(groups) || groups.length === 0) {
        return false
      }

      // change the groups from an array of object to just the group names
      groups = groups.map((group, i) => {
        return group.name
      })

      // compare the group array with some array of allowed groups for the component, return false if no matches true if matches
      let intersection = allowed.filter(x => -1 !== groups.indexOf(x))
      return intersection.length > 0
    }

    // should be return false if no refresh token
    return false
  },

  signIn(state) {
    announcementService.viewed(false)
    announcementService.viewed(false, 'legacyAgent')
    store.dispatch({ type: 'SET_AGENT_INFO', payload: undefined })
    return async dispatch => {
      try {
        // redux store requsting login state
        dispatch({ type: 'USERS_LOGIN_REQUEST', payload: state })

        // configs to make auth request
        const connect = {
          url: `${window._env_.REACT_APP_API_URL}/login/token/`,
          body: JSON.stringify({
            username: state.username,
            password: state.password,
          }),
          headers: { headers: { 'Content-Type': 'application/json' } },
        }

        const response = await axios.post(
          connect.url,
          connect.body,
          connect.headers
        )

        // Store access and refresh tokens in local storage
        localStorage.setItem('access', response.data.access)
        localStorage.setItem('refresh', response.data.refresh)

        // Set tokens in the store and Axios
        authService.setTokensInStore(
          response.data.access,
          response.data.refresh
        )

        // Fetch user info from the new API endpoint
        const userInfo = await authService.fetchUserInfoFromApi()

        // Dispatch login success with userInfo
        dispatch({ type: 'USERS_LOGIN_SUCCESS', payload: { state, userInfo } })

        // Proceed with routing only after everything is done
        authService.redirectByUserGroups() // Ensure this triggers a valid redirect
      } catch (error) {
        console.error('Failed to fetch user info:', error)
        dispatch({ type: 'USERS_LOGIN_FAILURE', payload: state })
      }
    }
  },

  // Sign-out method
  signOut(state) {
    return dispatch => {
      dispatch({ type: 'USERS_LOGOUT', payload: state })

      // Delete Tokens from local storage
      localStorage.removeItem('access')
      localStorage.removeItem('refresh')

      // Detele tokens from store
      dispatch({
        type: 'DELETE_TOKENS',
        payload: { refreshToken: '', accessToken: '' },
      })

      // stop the logout timers
      authService.stopLogoutPopupTimer()
      authService.stopLogoutTimer()
    }
  },

  // Refreshes the access token when failed request and then rerun api call with updated access token
  refreshAuthLogic(err) {
    const config = {
      requestBody: { refresh: authService.getRawRefreshToken() },
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }

    return err =>
      axios
        .post(
          `${window._env_.REACT_APP_API_URL}/login/token/refresh/`,
          config.requestBody,
          config.headers
        )
        .then(res => {
          const refreshToken =
            res.data.refresh || localStorage.getItem('refresh')
          // update the store
          store.dispatch({
            type: 'REFRESH_ACCESS_TOKEN',
            payload: {
              refreshToken: refreshToken,
              accessToken: res.data.access,
            },
          })

          // update local storage
          localStorage.setItem('access', res.data.access)
          localStorage.setItem('refresh', refreshToken)

          // set axios header with new token
          axios.defaults.headers.common['Authorization'] =
            'JWT ' + res.data.access

          return Promise.resolve()
        })
  },

  // Check if Axios header has auth and refresh token if needed
  checkAxiosAuthHeaderAndRefreshIfNull() {
    initService.createAuthRefreshInterceptor()
    initService.resetTokensOnBrowserRefresh()
  },

  // set the tokens in the store and axios header
  setTokensInStore(acc, ref) {
    // set to store
    store.dispatch({
      type: 'SET_TOKENS',
      payload: { refreshToken: ref, accessToken: acc },
    })

    // set default axios token header
    axios.defaults.headers.common['Authorization'] = 'JWT ' + acc
  },

  // auto logout and redirect based on
  autoLogout() {
    const refreshTokenData = this.getRefreshToken()
    const accessTokenData = this.getAccessToken()
    var currentTime = Math.round(new Date().getTime() / 1000)

    // compare token times and refresh token with current time, if access token or current time excede refresh tokens expiration then log out
    if (
      refreshTokenData.exp < accessTokenData.exp - 300 ||
      refreshTokenData.exp < currentTime
    ) {
      authService.logOut()
      return null
    }
  },

  // removes logout tokens AND redirects, mostly used for being called from non react components
  logOut() {
    store.dispatch(authService.signOut({}))
    window.location.replace('/logout')
  },

  // starts and restarts the lougout timer process
  resetLogoutTimer() {
    authService.stopLogoutPopupTimer()

    window.queLogoutPopup = setTimeout(() => {
      store.dispatch({
        type: 'OPEN_MODAL',
        payload: {},
      })

      authService.stopLogoutPopupTimer()

      window.logoutCountdown = setTimeout(
        authService.logOut,
        `${window._env_.REACT_APP_LOGOUT_TIMER}`
      )
    }, `${window._env_.REACT_APP_LOGOUT_POPUP_TIMER}`)
  },

  stopLogoutPopupTimer() {
    clearTimeout(window.queLogoutPopup)
  },

  stopLogoutTimer() {
    clearTimeout(window.logoutCountdown)
  },

  logoutPopupConfirmation() {
    store.dispatch({ type: 'CLOSE_MODAL', payload: {} })
    authService.stopLogoutTimer()
    authService.resetLogoutTimer()
  },

  // Parses the JWT token into a JSON object
  parseJwt(token) {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace('-', '+').replace('_', '/')
    return JSON.parse(window.atob(base64))
  },

  // Gets the encrypted token from local storage
  getRawToken(tokenType) {
    return localStorage.getItem(tokenType)
  },

  // Gets the encrypted refresh token
  getRawRefreshToken() {
    return authService.getRawToken('refresh')
  },

  // Gets the encrypted access token
  getRawAccessToken() {
    return authService.getRawToken('access')
  },

  // Gets the encrypted refresh JSON
  getRefreshToken() {
    let token = authService.getRawRefreshToken()
    return token ? authService.parseJwt(token) : false
  },

  // Gets the encrypted access JSON
  getAccessToken() {
    let token = authService.getRawAccessToken()
    return token ? authService.parseJwt(token) : false
  },

  // Gets the username() {
  getUserName() {
    const userInfo = this.getUserInfo()
    return userInfo ? userInfo.username : false
  },

  // Gets the full name of user {
  getFullName() {
    const userInfo = this.getUserInfo()
    return userInfo ? userInfo.full_name : false
  },

  getIsLegacyAgent() {
    const userInfo = this.getUserInfo()
    return userInfo ? userInfo.isLegacyAgent : false
  },

  getPrimaryAgentNumber() {
    const userInfo = this.getUserInfo()
    return userInfo ? userInfo.primaryAgentNumber : false
  },

  // Get a user group
  userHasGroup(groupName) {
    const userInfo = this.getUserInfo()
    if (!userInfo || !userInfo.groups) return false
    return userInfo.groups.some(group => group.name === groupName)
  },

  // handles auto redirect on login
  redirectByUserGroups() {
    let destination = ''
    const userInfo = this.getUserInfo()
    if (!userInfo || !userInfo.groups) return ''

    const groups = userInfo.groups.map(group => group.name)

    if (groups.indexOf('annuity-home-office') > -1) {
      destination = '/agent'
    } else if (groups.indexOf('annuity-client') > -1) {
      destination = '/client'
    } else if (groups.indexOf('annuity-agent') > -1) {
      destination = '/agent'
    }

    return destination
  },

  getUserGroups() {
    const userInfo = this.getUserInfo()
    if (!userInfo || !userInfo.groups) return []
    return userInfo.groups.map(group => group.name)
  },

  isAgency() {
    const userGroups = this.getUserGroups()
    return userGroups.some(
      group => group === 'annuity-agent' || group === 'annuity-home-office'
    )
  },

  isClient() {
    const userGroups = this.getUserGroups()
    return userGroups.some(group => group === 'annuity-client')
  },

  isAgent() {
    const userInfo = this.getUserInfo()
    if (!userInfo || !userInfo.groups) return false

    // Extract group names from userInfo
    const groups = userInfo.groups.map(group => group.name)

    // Check if the user belongs to the 'annuity-agent' group
    return groups.includes('annuity-agent')
  },

  // handles request to get a password email reset sent
  getResetEmail(state) {
    return dispatch => {
      // redux store requsting login state

      // configs to make auth request
      let connect = {
        url: `${window._env_.REACT_APP_API_URL}/login/password_reset/`,
        body: JSON.stringify({ email: state.email }),
        headers: {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'none',
          },
        },
      }

      // make the request
      axios
        .post(connect.url, connect.body, connect.headers)
        .then(response => {
          if (!response.data) {
          } else {
            dispatch({ type: 'USERS_PASSWORD_RESET_REQUEST', payload: state })
          }
        })
        .catch(error => {
          dispatch({ type: 'USERS_LOGIN_FAILURE', payload: state })
        })
    }
  },

  getAgentMembership() {
    const userInfo = this.getUserInfo()
    if (!userInfo || !userInfo.agentMembership) return null

    // Return agentMembership information
    return userInfo.agentMembership
  },
}

export default authService
