import '@fontsource/roboto/latin-300.css'
import '@fontsource/roboto/latin-400.css'
import '@fontsource/roboto/latin-500.css'
import '@fontsource/roboto/latin-700.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { SnackbarProvider } from 'notistack'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import './load_envs.js'
import './sentry.js'

import App from './App'
import authService from './_services/authService.js'
import { store } from './_store/store.js'
import './index.css'
import * as serviceWorker from './serviceWorker.js'

async function main() {
  await authService.initialize()

  const container = document.getElementById('root')
  const root = createRoot(container!)
  root.render(
    <Provider store={store}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        autoHideDuration={5000}
      >
        <App />
      </SnackbarProvider>
    </Provider>
  )

  serviceWorker.unregister()
}

main()
